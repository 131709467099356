import * as React from "react";
import "../styles/globals.css";
import "./index.scss";

import { StaticImage } from "gatsby-plugin-image";

import { BookDineplanButton } from "../components/bookingButtons";

import NavbarWeb from "../components/navbarWeb";
import MobileNav from "../components/navbarMobile";
import Footer from "../components/footer";

const IndexPage = () => {
	return (
		<main id="Booking">
			<NavbarWeb></NavbarWeb>
			<div className="mobileNavContainer">
				<div className="navLogo">
					<StaticImage
						src="../images/Kyoto-Brush-White.png"
						placeholder="blurred"
						width={346}
						height={720}
						formats={["auto", "webp", "avif"]}
						alt="Kyoto Garden Logo For Japenese Cape Town Restaurant"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
				<MobileNav></MobileNav>
			</div>

			<header className="hero-banner">
				<h1>Booking</h1>
				<div className="image-container">
					<StaticImage
						src="../images/Kyoto-garden-takeaway.jpg"
						placeholder="tracedSVG"
						width={1250}
						height={400}
						formats={["auto", "webp", "avif"]}
						alt="Koshi sushi chef"
						transformOptions={{ fit: "cover", cropFocus: "attention" }}
					/>
				</div>
			</header>

			<section className="order-section">
				<p>Make an easy booking with our partner platform, Dineplan:</p>
				<BookDineplanButton />
				<p>
					Alternatively, you can book by calling us at{" "}
					<a className="location-link booking" href="tel:+27214222001">
						Tel: (+27) 2142 22001
					</a>
				</p>
			</section>

			<Footer></Footer>
		</main>
	);
};

export default IndexPage;

export const Head = () => (
	<>
		<title>Booking</title>
		<meta
			name="description"
			content="Make a reservation at Kyoto Garden, the premier Japanese and sushi restaurant in Cape Town. You can book a table by calling us or through our partner website Dineplan."
		/>
	</>
);
